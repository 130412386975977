import React from 'react';
import { getEateries } from '../../services/eatery';
import { Edible, Review, getEdibles } from '../../services/edible';
import { ReviewWidget } from '../../components/reviewWidget';
import './home.css';

export const Home: React.FunctionComponent = () => {
  const maxReviews = 3;

  const edibles: Edible[] = [];
  const reviews: Review[] = [];
  const eateries = getEateries({});

  eateries.forEach((eatery) => {
    const ediblesResults = getEdibles(eatery.id);
    edibles.push(...ediblesResults);

    ediblesResults.forEach((edible) => {
      reviews.push(...edible.reviews);
    })
  });

  // put the newest reviews first
  reviews.sort((a, b) => a.dateAdded > b.dateAdded ? -1 :
    a.dateAdded < b.dateAdded ? 1 : 0);

  return (
    <main className='container'>
      <article className='reviews-featured'>
        <h1>Recent Voganalyses</h1>
        <section className='reviews'>
          {reviews.slice(0, maxReviews).map((review) => {
            const edible = edibles.find((e) => e.id === review.edibleId);
            const eatery = eateries.find((e) => e.id === edible?.eateryId);

            return (
              edible !== undefined &&
              eatery !== undefined &&
              <ReviewWidget key={review.id}
                eateryName={eatery.name}
                eateryId={eatery.id}
                edibleName={edible.name}
                ediblePhoto={edible.photo}
                rating={review.rating}
                reviewDate={review.dateAdded}
                reviwerId={review.userId} />
            );
          })}
        </section>
      </article>
    </main>
  );
}
