import React, { useState } from 'react';
import './header.css';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export const Header: React.FunctionComponent = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  function composeHeaderClassName() {
    return isFullScreen ? 'fullscreen' : '';
  }

  function closeFullScreen() {
    setIsFullScreen(false);
  }

  function toggleFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  return (
    <header id="site-header" className={composeHeaderClassName()}>
      <nav>
        <ul className='site-menu' onClick={closeFullScreen}>
          <li className='home'>
            <NavLink to="/" end>
              <ReactSVG className='logo' src='/img/theme/favicon.svg' />
              <div>
                <div className='title'>The Vogel Scale</div>
                <div className='subtitle'>Your highly opinionated guide to vegan decadence.</div>
              </div>
            </NavLink>
          </li>
          <li><NavLink to="/eateries">Eateries</NavLink></li>
          <li><NavLink to="/about">What <em>is</em> this?</NavLink></li>
        </ul>
        <div id='site-menu-toggle' onClick={toggleFullScreen}>
          <ReactSVG id='hamburger' src='/img/theme/hamburger.svg' />
        </div>
      </nav>
    </header>
  );
}
