import { eateryData } from "./data";
import { Eatery } from "./eatery";

export function getEateries(eateryish: string): Eatery;
export function getEateries(eateryish: Partial<Eatery>): Eatery[];
export function getEateries(eateryish: string | Partial<Eatery>): Eatery | Eatery[] {
  if (typeof eateryish === 'string') {
    const id = eateryish;
    const eatery = eateryData.find((candidate) => candidate.id === id);
    if (eatery === undefined) {
      throw new Error(`No eatery with ID ${id}`);
    }
    return eatery;
  }

  // we'll implement a bona fide search later; for now just return them all
  return eateryData;
}
