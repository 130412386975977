import React from 'react';
import { ReactSVG } from 'react-svg';
import { Review as ReviewInterface } from '../../services/edible';
import './review.css';

export interface EdibleProps {
  data: ReviewInterface;
}

export const Review: React.FunctionComponent<EdibleProps> = (props) => {
  const { data: review } = props;
  return (
    <article className='review'>
      <dl className={'rating-'.concat(review.rating.toString())}>
        <dt>Rating</dt>
        <dd>
          <span className='plain-text'>{review.rating.toString()}/5</span>
          <ReactSVG src='/img/theme/scale.svg' />
        </dd>
      </dl>
      <section dangerouslySetInnerHTML={{ __html: review.text }} />
      <footer>
        Reviewed by {review.userId} on {review.dateAdded.toLocaleDateString()}
      </footer>
    </article>
  )
}
