import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { Eatery as EateryInterface } from '../../services/eatery';
import './eatery.css';
import { Edibles } from '../edibles';

export const Eatery: React.FunctionComponent = () => {
  // ugh, this is so dirty but the router lib is surprisingly Typescript-unfriendly.
  // might look at this again later
  const eatery = useLoaderData() as EateryInterface;

  return (
    <main className='container'>
      <article className='eatery'>
        <h1>{eatery.name}</h1>
        <section className='eatery-wrapper'>
          <section className='eatery-description' dangerouslySetInnerHTML={{ __html: eatery.description }}></section>
          <section className='eatery-picture shadow'>
            <div className='border-helper'>
              {eatery.photo && <img src={eatery.photo} />}
            </div>
          </section>
          {/* TODO: create a component for addresses that is country-sensitive */}
          <address>
            {eatery.website
              ? <a href={eatery.website.toString()} target='_blank' rel='noreferrer'>
                {eatery.name}
              </a>
              : eatery.name
            }<br />
            {eatery.address.street}<br />
            {eatery.address.postalCode} {eatery.address.city}, {eatery.address.country}
          </address>
        </section>
      </article>
      <Edibles eateryId={eatery.id} />
    </main>
  )
}
