import React from 'react';
import { Link } from 'react-router-dom';
import './reviewWidget.css';

export interface ReviewWidgetProps {
  eateryName: string;
  eateryId: string;
  edibleName: string;
  ediblePhoto: string;
  rating: 1 | 2 | 3 | 4 | 5;
  reviewDate: Date;
  reviwerId: string;
}

export const ReviewWidget: React.FunctionComponent<ReviewWidgetProps> = (props) => {
  const { eateryId, eateryName, edibleName, ediblePhoto, rating, reviewDate, reviwerId } = props;

  return (
    <article className='review-widget shadow'>
      <div className='border-helper'>
        <div className='review-widget-inner'>
          <img src={ediblePhoto} />
          <section className='container'>
            <h2>{edibleName}</h2>
            <dl>
              <dt>Eatery:</dt>
              <dd><Link to={`/eateries/${eateryId}`}>{eateryName}</Link></dd>
              <dt>Summary:</dt>
              <dd>{rating}/5</dd>
              <dt>Date:</dt>
              <dd>{reviewDate.toLocaleDateString()}</dd>
              <dt>Analyst:</dt>
              <dd>{reviwerId}</dd>
            </dl>
          </section>
        </div>
      </div>
    </article>
  )
};
