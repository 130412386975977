import React, { Fragment } from 'react';
import { Header } from './components/header';
import { createBrowserRouter, redirect, RouterProvider, Outlet } from 'react-router-dom';
import { Eatery as EateryInterface } from './services/eatery';
import { About } from './containers/about';
import { Home } from './containers/home';
import { Eateries } from './containers/eateries';
import { Eatery } from './containers/eatery';
import { getEateries } from './services/eatery';

export const App: React.FunctionComponent = () => {
  const router = createBrowserRouter([
    {
      // yuck, this routing library has gotten pretty grabby and opinionated;
      // a pathless route serves as the parent of all other routes so we can
      // use NavLinks in the context of a Router component.
      element: <Fragment>
        <Header />
        <Outlet />
      </Fragment>,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/about',
          element: <About />,
        },
        {
          path: '/eateries',
          element: <Eateries />,
        },
        {
          path: '/eateries/:id',
          element: <Eatery />,
          loader: async ({ params }) => {
            let data: EateryInterface | undefined;
            const { id } = params;
            if (typeof id === 'string') {
              data = getEateries(id);
            }

            if (typeof data === 'undefined') {
              redirect('/eateries');
            }
            return data;
          },
        },

      ],
    },
  ])


  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}
