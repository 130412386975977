import React from 'react';
import { ReactSVG } from 'react-svg';
import './about.css';

export const About: React.FunctionComponent = () => {
  return (
    <main className='container'>
      <article className='about'>
        <h1>What <em>Is</em> This?</h1>
        <section className='bio'>
          <div className='bio-text'>
            <p>
              <strong>This site is made in honor of our friend Pat Vogel.</strong> Pat is the member of your crew who will lead
              a caravan to the evening's third restaurant for post-second-dinner dessert; the tour bus driver whose every "wrong turn"
              inevitably becomes a detour to a new restaurant he heard good things about; the trailblazer who will
              find a way to cook up a vegan version of KFC's <a
                href='https://en.wikipedia.org/wiki/Double_Down_(sandwich)' target='_blank' rel='noreferrer'>
                Double Down monstrosity</a> so you don't have to.
            </p>
            <p>
              <strong>Pat doesn't do half-measures.</strong> We'll take that veggie sausage with <em><a
                href='https://youtu.be/wIoNaAs-KGg' target='_blank' rel='noreferrer'>all</a></em> the fixings,
              thank you very much. Is it going to hurt? Probably. Is it to going to cause permanent
              bodily harm? Unlikely, but… maybe? But will it have been worth it? There's only one way to find out.</p>
            <p>
              <strong>Pat makes Chuck Norris look like Charlie Brown.</strong> Before you've even thought
              about stuffing some vegan novelty in your face hole, Pat has written a dissertation on its origins and tested three different
              versions of it, all from the seat of his trusty (synthetic) steed, while launching the sickest riffs from a doubleneck guitar
              at an oncoming train. He was a beta tester for the first generation product. He's tasted the bleeding edge. He's basked in
              excellence and bawled at culinary catastrophes few of us will ever begin to comprehend.
            </p>
          </div>
          <div className='bio-image'>
            <ReactSVG src='/img/theme/favicon.svg' />
          </div>
        </section>
        <h2>About the Rating System</h2>
        <p>
          <strong>Look, we're not even going to <em>try</em> to be objective or consistent here.</strong> Reviews are
          inherently subjective, and rating every facet of a meal (presentation? taste? texture? ambience? value?) is no fun.
        </p>
        <p>
          <strong>This is the Vogel Scale.</strong> Of course flavor is a consideration, but the Vogel Scale goes beyond
          the mere physical plane. To properly evaluate a dish, one's tongue must be relieved from simple taste-testing
          so that it may ask the metaphysical questions: Did the dish meet its full potential…
          and then keep going? Did it pen a new chapter in the Tome of Decadence? Would it have made Caligula blush?
          In short, did it strive for and achieve <a href='https://youtu.be/gukTnUSSHNs' target='_blank' rel='noreferrer'>All</a>?
        </p>
      </article>
    </main>
  );
}
