import { Eatery } from "./eatery";

export const eateryData: Eatery[] = [
  {
    address: {
      city: 'Lisbon',
      country: 'Portugal',
      postalCode: '1200-149',
      street: 'Rua Dom Luis I 19C',
    },
    coordinates: {
      latitude: 38.7073331,
      longitude: -9.151106,
    },
    description: '<p>Upscale all-vegan restaurant with a focus on healthy eating.</p>\
      <p>Offers gluten-free options (or may be entirely gluten-free?). Develops cashew cheeses \
      and kombucha varities in-house.</p>',
    id: 'e762415190',
    name: 'Orteá Vegan Collective (O Botanista)',
    photo: '/img/eateries/e762415190-orteá-vegan-collective.png',
    website: new URL('https://obotanista.eatbu.com'),
  },
  {
    address: {
      city: 'Lisbon',
      country: 'Portugal',
      postalCode: '1100-289',
      street: 'Beco do Jasmim 18',
    },
    coordinates: {
      latitude: 38.7159088,
      longitude: -9.1343002,
    },
    description: "<p>Charming all-vegan hole-in-the-wall tucked away in the dizzying footpaths of Alfama, \
    Lisbon's oldest neighborhood. Indoor seating is limited&mdash;most meals are served on the steps \
    of the adjacent plaza.</p>\
    <p>Hippie vibes. Vocally LGBTQ+-friendly. Very laid-back.</p>\
    <p>The menu changes frequently, but homemade cashew cheese and a solid selection of desserts are staples.</p>",
    id: '0e7971950e',
    name: 'The Food Temple',
    photo: '/img/eateries/0e7971950e-food-temple.png',
    website: new URL('https://www.facebook.com/FoodTemple'),
  },
  {
    address: {
      city: 'Lisbon',
      country: 'Portugal',
      postalCode: '1200-334',
      street: 'Rua do Norte 64',
    },
    coordinates: {
      latitude: 38.711879,
      longitude: -9.1462759,
    },
    description: "<p>Nostalgia-soaked cereal bar/laboratory that seeks to answer the questions every kid would explore should they somehow gain control over the grocery budget: who would win in a fight&mdash;Cap'n Crunch or Fred Flintstone? What if you could cross-breed Toucan Sam and Tony the Tiger&mdash;would you get a gryphon and more importantly <em>HOW DELICIOUS WOULD THEIR CEREAL BE‽‽‽</em></p><p>RIYL: Roy Lichtenstein, Elvis Presley, the Beatles, Star Wars, rotary phones.</p>",
    id: '74fddac46c',
    name: 'Pop Cereal Café',
    photo: '/img/eateries/74fddac46c-pop-cereal-café.png',
    website: new URL('https://popcereal.com'),
  }
];
