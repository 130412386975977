import React, { Fragment, useState } from 'react';
import { Edible as EdibleInterface } from '../../services/edible';
import { Review } from '../review/review';
import './edible.css';

export interface EdibleProps {
  data: EdibleInterface;
  isOpen?: boolean;
}

export const Edible: React.FunctionComponent<EdibleProps> = (props) => {
  const { data: edible, isOpen: isOpenRaw } = props;
  const [isOpen, setIsOpen] = useState(typeof isOpenRaw === 'undefined' ? false : isOpenRaw);

  function composeEdibleClassName() {
    return `edible-${isOpen ? 'open' : 'closed'}`;
  }

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <article className={composeEdibleClassName()} onClick={toggleIsOpen}>
      <header>
        <h3>{edible.name}</h3>
        {edible.description &&
          <p>{edible.description}</p>
        }
      </header>
      {isOpen &&
        <Fragment>
          <img src={edible.photo} />
          <section className='reviews'>
            <h4>{edible.reviews.length > 1 ? 'Voganalyses' : 'Voganalysis'}</h4>
            {edible.reviews.map((review) => (
              <Review data={review} key={review.id} />
            ))}
          </section>
        </Fragment>
      }
    </article>
  )
}
