import React from 'react';
import './edibles.css';
import { getEdibles } from '../../services/edible';
import { Edible } from '../edible';

export interface EdiblesProps {
  eateryId: string;
}

export const Edibles: React.FunctionComponent<EdiblesProps> = (props: EdiblesProps) => {
  const { eateryId } = props;
  const edibles = getEdibles(eateryId);

  return (
    <section className='menu'>
      <hgroup>
        <h2>Menu</h2>
        <p>Only menu items which have been subjected to Voganalysis are listed.</p>
      </hgroup>
      <div><strong>~~~</strong></div>
      {edibles.map((edible) => (
        <Edible data={edible} key={edible.id} />
      ))}
    </section>
  )
}
