import React from 'react';
import { getEateries } from '../../services/eatery';
import { Link } from 'react-router-dom';

export const Eateries: React.FunctionComponent = () => {
  const eateries = getEateries({});

  return (
    <main className='container'>
      <h1>Eateries</h1>
      <ul>
        {eateries.map((eatery) => (
          <li key={eatery.id}>
            <Link to={eatery.id}>
              {eatery.name}
            </Link>
          </li>
        ))}
      </ul>
    </main>
  );
}
