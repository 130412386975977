import { Edible } from "./edible";

export const edibleData: Edible[] = [
  {
    description: 'Stuffed pancake. Saltered caramel. Cookie. Chocolate. Hazelnut ice cream.',
    eateryId: 'e762415190',
    id: '39cb36e824',
    name: 'Cookie Dough Pancakes',
    photo: '/img/edibles/39cb36e824-cookie-dough-pancakes.png',
    reviews: [
      {
        dateAdded: new Date(2023, 6, 21),
        edibleId: '39cb36e824',
        id: 'e694667e43',
        rating: 4,
        text: "<p>4/5 on the Vogel scale. I couldn't tell the pancakes were gluten-free, but the little cookie might as well have been a parsley garnish. Also, the ice cream was tasty but a little crumbly. It eventually melted down with the caramel sauce, but crumbly ice cream can score no higher than a 4/5.",
        userId: 'universalhandle',
      },
    ],
  },
  {
    description: 'Smoked lime. Whipped cream. Almonds.',
    eateryId: 'e762415190',
    id: '3b33be9851',
    name: 'The beauty of small things',
    photo: '/img/edibles/3b33be9851-the-beauty-of-small-things.png',
    reviews: [
      {
        dateAdded: new Date(2023, 6, 21),
        edibleId: '3b33be9851',
        id: 'd786d71e01',
        rating: 2,
        text: "<p>Delicious, but (1) this is a dessert item, and not one of my teeth dissolved in a wash of sugary sweetness, (2) it's so small, and (3) I should be able to eat all of it&mdash;I took a bite of the lime slice and its sourness almost turned me inside out. Where's the decadence? Do you even know what a Vogel is? 2/5.</p>",
        userId: 'universalhandle',
      },
    ],
  },
  {
    description: 'For those who want it all',
    eateryId: '0e7971950e',
    id: '7b53d88fc6',
    name: 'Dessert Tasting Plate',
    photo: '/img/edibles/7b53d88fc6-dessert-tasting-plate.png',
    reviews: [
      {
        dateAdded: new Date(2023, 7, 21),
        edibleId: '7b53d88fc6',
        id: '4387ef91e7',
        rating: 5,
        text: "<p>Can't decide which dessert sounds best? Devour down-sized versions of them all, discreetly, by ordering the <em>Dessert Tasting Plate</em>. \
          The audacity&mdash;and foresight!&mdash;required to print this menu item guarantees a minimum score of 4 Vogels.</p>\
          <p>The folks at The Food Temple are not masters of vision alone, however; they are also masters of execution. On the day I visited, \
          the dessert options were <em>Cassava Cake</em> (cassava cake, coconut, carmelized pumpkin &amp; rice paper), <em>Raw Cake</em> \
          (orange &amp; lemon cream, dates &amp; nuts base, ginger &amp; sesame brittle), and <em>Chocolate &amp; Miso Caramel Tart</em> \
          (creamy chocolate tart, almond base, with peanut cracker and miso caramel). Miso is not a thing I want in my desserts (or in \
          many contexts at all, honestly), but when the plate stopped spinning on the miso tart, and it came time to try it, I was surprised and delighted.</p>\
          <p>After a hearty dinner, a wave of <a href='https://finalshemp.bandcamp.com/track/pregret' target='_blank' rel='noreferrer'>\
          pregret</a> washed over me when the plate arrived. \"This is too much for two people,\" I thought. When I woke up at 5:30 AM with \
          intense heartburn, I knew I had truly Vogeled, and that this menu item could score no lower than perfect.</p>",
        userId: 'universalhandle',
      }
    ],
  },
  {
    description: 'Choose your favorite cereals and mix them all in the same bowl. National or international the decision is yours. Take the risk and discover new flavors and textures.',
    eateryId: '74fddac46c',
    id: 'b52951843c',
    name: 'Do it Yourself',
    photo: '/img/edibles/b52951843c-do-it-yourself.png',
    reviews: [
      {
        dateAdded: new Date(2023, 6, 23),
        edibleId: 'b52951843c',
        id: 'a39976a21e',
        rating: 4,
        text: "<p>First thought: that's a punk rock name for a menu item. Second thought: yes, please!</p>\
          <p>This dish would get a 5/5 on the truth-in-advertising scale&mdash;it is exactly what it claims to be, with dozens of cereals \
          and toppings to choose from, plus several suggested combinations of flavors based, I'm sure, on the strictest adherence to the \
          scientific method. But that's not what we're here to do. What we want to know is: how hard did it Vogel?</p>\
          <p>To get the most accurate measurement of Pop Cereal Café's Vogel Potential, I had to cast aside established science and \
          author my own delight or demise. The <em>Do it Yourself</em>, which comes in two sizes, afforded me precisely that \
          opportunity. Naturally, I went for the bigger option, which allows the eater to choose five cereals and two \
          toppings.</p>\
          <p>Pop Cereal Café's cereal savant nodded sagely and appreciatively as I combined Cinnamon Toast Crunch Chocolate Churros with \
          French Toast Crunch and Kellogg's Cinnabon. He nearly applauded when I chose oat over the other vegan milks. \
          He was, however, flabbergasted when I added Fruity Pebbles and Cap'n Crunch's OOPS! All Berries to my bowl.</p>\
          <p>The toppings are all over the board, ranging from dried fruits to nuts, syrups, and actual candy. My first choice of topping was \
          a vegan cookie whose name promptly surfed out of my head on the glistening red wave of my second topping: strawberry syrup.</p>\
          <p>I staggered away from that meal thinking, \"Today I lived,\" but as the tide of strawberry goop receded, I regained control of my faculties \
          and realized I had been cheated. You see, Pop Cereal Café is not an all-vegan establishment, so not every cereal or topping was \"on the menu,\" \
          so to speak. While I have come to terms with the fact that not everyone caters to vegan lifestyles, I can't get over one thing I saw in their comic \
          book of menu. Pictured there is a bowl of cereals swimming in utopic bliss around an island of ice cream. I want \
          that. Vegan ice cream is so widely available&mdash;such an easy menu add&mdash;that I must reluctantly dock one Vogel from what otherwise \
          would have been a perfect score.</p>",
        userId: 'universalhandle',
      }
    ],
  }
];
